import React from 'react';

import Layout from "../layouts/site/layout";
import iso from '../images/Approvals/iso-900-iso-14001.gif'

function Approvals() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-10">
                <h2 className="text-red-1000 font-bold text-base">Quality approvals</h2>
                <div className="grid grid-cols-2">
                    <div className="col-span-1">
                        <p className="mt-2">ISO-9001:2000</p>
                        <p className="mt-2">ISO-14001</p>
                        <p className="mt-2">Norsok F44, F51, F55</p>
                        <p className="mt-2">Statoil</p>
                        <p className="mt-2">Non-destructive testing qualified to ASNT Level II and PCN Level II</p>
                        <p className="mt-2">Heat treat furnaces calibrated to API 6A</p>
                    </div>
                    <div className="col-span-1 flex justify-center">
                        <img src={iso} className="App-logo pt-4 pr-1" alt="logo" />
                    </div>
                </div>
                <h3 className="text-[15px] text-center font-bold mt-3 mb-9">Forging Solutions Transforming Business</h3>
            </div>
       </Layout>
    </div>
  );
}

export default Approvals;
